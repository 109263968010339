import * as React from "react"
import Website from "@src/components/wrappers/website"
import Nav from "@src/components/marketing/headerAlt"
import Footer from "@components/marketing/footer"
import Button from "@src/components/marketing/button"

const seo = {
  url: "/affiliates",
  title: "Sheetsync affiliate program",
  description: "Earn a commission for every customer you refer to Sheetsync."
}

export default class Affiliates extends React.Component {
	render() {
		return (
      <Website seo={seo} marketing>
        <Nav />
        <section className="text-center py-10 sm:py-16 md:py-24">
          <div className="max-w-6xl m-auto px-8">
            <h1 className="basis text-4xl md:text-5xl leading-tight font-bold mb-2 text-gray-900">
              Sheetsync affiliate program
            </h1>
            <h2 className="max-w-2xl mx-auto text-2xl sm:text-xl mb-6">
              Earn a 20% commission for every customer you refer to Sheetsync.
            </h2>
            <Button medium external href="https://forms.gle/xy4mxmGAeyaq9FM27">
                Sign up
            </Button>
          </div>
        </section>

        <Footer/>
      </Website>
    )
  }
}
